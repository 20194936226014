<template>
    <div class="MintPool">
        <div class="recruit_title">
            <div style="transform: rotate(90deg); color: #000" @click="toprouter"><van-icon name="down" /></div>
            <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
            <div></div>
        </div>
        <div class="MintPool_box">
            <div class="MintPool_box_top">
            <div style="display: flex; flex-direction: column;">
                <span style="font-size: 12px; font-weight: 500; opacity: 0.6;"> {{ $t('lang.今日收益') }}</span>
                <span>{{ parseFloat(rewardInfo.static_reward +rewardInfo.cp_reward + rewardInfo.share_reward).toFixed(2) || 0 }}</span>
            </div>
               
                <img src="@/assets/img/MintPool_img1.png" alt="">
            </div>
            <div class="MintPool_box_bottom">
                <div class="MintPool_box_bottom_left">
                    <div>
                        <span> {{ $t('lang.算力奖励') }}</span>
                        <span style="color: #FFFFFF; font-size: 12px;">{{ rewardInfo.static_reward || 0 }}</span>
                    </div>
                    <div>
                        <span> {{ $t('lang.社区奖励') }}</span>
                        <span style="color: #FFFFFF; font-size: 12px;">{{ rewardInfo.cp_reward || 0 }}</span>
                    </div>
                    <div>
                        <span>{{ $t('lang.分享奖励') }}</span>
                        <span style="color: #FFFFFF; font-size: 12px;">{{ rewardInfo.share_reward || 0 }}</span>
                    </div>
                </div>
                <button @click="GoMintBill" class="MintPool_box_bottom_button">
                    {{ $t('lang.账单') }}
                </button>
            </div>
        </div>
        <div class="MintPool_box2">

            <div class="MintPool_box2_line">
                <img src="@/assets/img/MintPool_box2_img2.png" alt="">
                <span>{{ $t('lang.全网质押算力') }}：{{ summaryInfo.lp_cp || 0 }}</span>
            </div>
        </div>
        <div class="lookboxs">
            <div class="lookbox">
                <div class="abs_look">{{ $t('lang.o105') }}</div>
                <div class="box">
                    <div class="box_num">{{ teamInfo.lp_cp || 0 }}</div>
                    <img src="@/assets/img/lookbox_icon1.png" alt="" />
                    <div class="box_title">{{ $t('lang.个人算力') }}</div>
                </div>
            </div>
            <div class="lookbox" style="border: 1px solid #75CF5F;">
                <div class="abs_look" style="background: #75CF5F;">{{ $t('lang.o105')
                    }}</div>
                <div class="box" style="background: linear-gradient(0deg, #75CF5F 0%, #FFFFFF 100%)">
                    <div class="box_num">{{ teamInfo.lp_max_zone_cp || 0 }}</div>
                    <img src="@/assets/img/lookbox_icon2.png" alt="" />
                    <div class="box_title">{{ $t('lang.最大社区') }}</div>
                </div>
            </div>
            <div class="lookbox" style="border: 1px solid #66B4AE;">
                <div class="abs_look" style="background:#66B4AE;">{{ $t('lang.o105')
                    }}</div>
                <div class="box" style="background: linear-gradient(0deg, #66B4AE 0%, #FFFFFF 100%)">
                    <div class="box_num">{{ teamInfo.lp_children_cp || 0 }}</div>
                    <img src="@/assets/img/lookbox_icon3.png" alt="" />
                    <div class="box_title">{{ $t('lang.其他社区') }}</div>
                </div>
            </div>
        </div>
        <Luckylist />
        <DTlist :rankInfo="rankInfo" />
        <sharelist :shareInfo="shareInfo" />
        <div class="MintPoolist">
            <div class="MintPoolist_box" v-for="(item, index) in lpConfiglist" :key="index">
                <div class="MintPoolist_box_top">
                    <div class="MintPoolist_box_top_logo">
                        <img :src="getFullImagePath(item.logo)" alt="">

                    </div>
                    <span>{{ item.name }}</span>
                </div>
                <div class="MintPoolist_box_box" v-loading="MintPoolLoading && item.name == MintPoolItem.name">
                    <div class="MintPoolist_box_box_title">{{ item.amount > 0 ? $t('lang.挖矿中') : $t('lang.未开启') }}</div>
                    <div class="MintPoolist_box_box_num">
                        <div class="MintPoolist_box_box_num_left">
                            <span>Power≈ {{ item.amount }} </span>
                            <span v-if="item.name == 'RAC/USDT'">TVL ≈{{ significantDigits(item.amount * RACUSDTlpPrice)
                                || 0 }}</span>
                            <span v-else>TVL ≈{{ significantDigits(item.amount * RACHamsterlpPrice) || 0 }}</span>
                        </div>
                        <div @click="addMintPoolShowOpen(item)" class="MintPoolist_box_box_num_right">
                            <van-icon name="plus" />
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <div class="MintPoolistpledge">
            <div class="MintPoolistpledge_titles">
                <div @click="activeMintPoolistpledgeFunc(0)" :class="activeMintPoolistpledge == 0 ? 'active' : ''">{{
                    $t('lang.质押中') }}
                </div>
                <div @click="activeMintPoolistpledgeFunc(1)" :class="activeMintPoolistpledge == 1 ? 'active' : ''">{{
                    $t('lang.已赎回') }}
                </div>
            </div>
            <div class="MintPoolistpledge_box">
                <div class="MintPoolistpledge_box_Nolength" v-if="MintPoolListInfo.list.length == 0">
                    <img src="@/assets/img/MintPoolistpledge_box_Nolength.png" alt="">
                    <span>{{ $t('lang.暂无数据') }}~</span>
                </div>
                <div class="MintPoolistpledge_box_list" v-else>
                    <van-list v-model="MintPoolListInfo.loading" :finished="MintPoolListInfo.finished"
                        :finished-text="`${$t('lang.d145')}`" @load="onLoadMintPoolListInfo">
                        <div class="MintPoolistpledge_box_list_li" v-for="(item, index) in MintPoolListInfo.list"
                            :key="index">
                            <div class="MintPoolistpledge_box_list_li_left">
                                <img src="@/assets/img/MintPool_li_img.png" alt="">
                            </div>
                            <div class="MintPoolistpledge_box_list_li_right">
                                <div> {{ $t('lang.质押类型') }} ：{{ item.lp }}</div>
                                <div>{{ $t('lang.质押时间') }} ：{{ item.created_at }} </div>
                                <div> {{ $t('lang.质押数量') }}： {{ item.amount }}</div>
                                <div v-if="activeMintPoolistpledge == 1"> {{ $t('lang.赎回时间') }}： {{ item.finished_at }}
                                </div>
                                <div class="MintPoolistpledge_box_list_li_right_button"
                                    v-if="activeMintPoolistpledge == 1">
                                    {{ $t('lang.赎回') }}</div>
                                <div @click="lpholdMintPoolredeemShowFunc(item)"
                                    style="background: #66B4AE; color: #FFFFFF;"
                                    class="MintPoolistpledge_box_list_li_right_button" v-else>{{ $t('lang.赎回') }}</div>

                            </div>
                        </div>
                    </van-list>

                </div>
            </div>
        </div>
        <van-popup v-model="addMintPoolShow" position="bottom" round closeable>
            <div class="addMintPoolShow">
                <div class="addMintPoolShow_title">{{ $t('lang.账户余额') }}：</div>
                <div class="addMintPoolShow_input"><span v-if="!balanceLoading">{{ balance }}</span> <van-loading
                        v-else /> </div>
                <div class="addMintPoolShow_title" style="margin-top: 20px;">{{ $t('lang.质押数量') }}：</div>
                <div class="addMintPoolShow_input">
                    <input type="number" v-model="MintPoolInputNum" @input="MintPoolInputNumChange">
                    <div class="addMintPoolShow_input_all" @click="MintPoolNumAll">{{ $t('lang.全部') }}</div>
                </div>
                <div class="addMintPoolShow_tips">{{ $t('lang.单次新增数量为1的倍数') }}</div>
                <button class="addMintPoolShow_btn" @click="enteraddMintPoolShow">{{ $t('lang.确定') }}</button>
            </div>
        </van-popup>
        <van-popup v-model="MintPoolredeemShow" position="center" round>
            <div class="MintPoolredeemShow">
                <img src="@/assets/img/MintPoolredeemShow_img.png" alt="">
                <span>{{ MintPoolredeemComputedDateText }}</span>
                <div class="MintPoolredeemShow_buttons">
                    <button class="confirm" @click="lpholdMintPoolredeem">{{ $t('lang.确定') }}</button>
                    <button class="cancel" @click="MintPoolredeemShow = false">{{ $t('lang.取消') }}</button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import Luckylist from './components/Luckylist.vue';
import DTlist from './components/DTlist.vue';
import sharelist from './components/sharelist.vue';
import { MintPool_api, web3_api } from '@/api/index';
import { mapState } from 'vuex';
import { getFullImagePath } from '@/utils/common';
import { significantDigits, accDiv, accMul, formatAmount } from '@/utils/format'
export default {
    data() {
        return {
            MintPoolredeemItems: {},
            MintPoolredeemShow: false,
            addMintPoolShow: false,
            MintPoolLpConfig: {},
            activeMintPoolistpledge: 0,
            teamInfo: {},
            summaryInfo: {},
            rankInfo: {},
            shareInfo: {},
            rewardInfo: {},
            lpConfiglist: [],
            RACHamsterlpPrice: 0,
            RACUSDTlpPrice: 0,
            MintPoolItem: {},
            balance: 0,
            balanceLoading: false,
            MintPoolInputNum: 0,
            MintPoolLoading: false,
            MintPoolListInfo: {
                loading: false,
                finished: false,
                list: [],
                info: {
                    page: 1,
                    page_size: 10,
                    status: '1'
                }

            },
        }
    },
    components: {
        Luckylist, DTlist, sharelist
    },
    computed: {
        ...mapState(['account', 'lang']),
        MintPoolredeemComputedDateText() {
            const beginTs = this.MintPoolredeemItems.begin_ts;
            const nowTs = Date.now();

            // 自动判断 `beginTs` 单位，如果是 10 位，转换为 13 位
            const normalizedBeginTs = beginTs < 10000000000 ? beginTs * 1000 : beginTs;

            const duration = Math.floor((nowTs - normalizedBeginTs) / (1000 * 60 * 60 * 24));

            if (duration < 7) {
                return this.$t('lang.当前质押未满天需收取管理费', { day: 7, rate: this.MintPoolLpConfig.redeem_fee1 * 100 });
            } else if (duration < 15) {
                return this.$t('lang.当前质押未满天需收取管理费', { day: 15, rate: this.MintPoolLpConfig.redeem_fee2 * 100 });
            } else {
                return this.$t('lang.赎回后不再享受发行激励');
            }
        }
    },
    watch: {
        account() {

            this.init()
        },
        lang() {
            this.init()
        }
    },
    created() {
        this.init()
    },
    methods: {
        getFullImagePath,
        significantDigits,

        init() {
            if (!this.account) {
                return
            }

            Promise.all([
                this.lpuserteaminfo(),
                this.lpuserranklist(),
                this.lpusersharelist(),
                this.lpusertodayreward(),
                this.lpusersummary(),
                this.lplist(),
                this.lpconfig(),
                this.initMintPoolListInfo(),
                this.getLP_TVL_InUSDT()
            ])
        },
        MintPoolInputNumChange() {
            if (this.MintPoolInputNum == '' || this.MintPoolInputNum == null) return this.MintPoolInputNum = 0
            this.MintPoolInputNum = parseInt(this.MintPoolInputNum)
        },
        lpuserteaminfo() {
            MintPool_api.lpuserteaminfo(this.account).then(res => {
                if (res.code == 200) {
                    this.teamInfo = res.data
                }
            })
        },
        transferERC20Token() {
            this.MintPoolLoading = true
            web3_api.transferERC20Token(this.MintPoolItem.contract, this.account, this.MintPoolItem.receiver, this.MintPoolInputNum).then(res => {
                MintPool_api.lpholdentrymark({ tx_hash: res.transactionHash }, this.account).then(val => {
                    if (val.code == 200) {
                        this.init()
                    }
                    this.MintPoolLoading = false
                    this.$toast(val.msg)
                })
            }).catch(err => {
                this.MintPoolLoading = false
            })
        },
        getLP_TVL_InUSDT() {

            web3_api.getLP_TVL_InUSDT(['0x9f3E1B10c58384Ab472393455d2f870427fF9153'], '0x024a9AD0ECFaa8b3566D3310AFFb358379a55e7a').then(res => {
                this.RACUSDTlpPrice = res
            })
            web3_api.getLP_TVL_InUSDT(['0xce18fb629add28a26c4c1a4ff8d95041b98c8677', '0x9f3E1B10c58384Ab472393455d2f870427fF9153'], '0x024a9AD0ECFaa8b3566D3310AFFb358379a55e7a').then(res => {

                this.RACHamsterlpPrice = res

            })
        },
        lpuserranklist() {
            MintPool_api.lpuserranklist(this.account).then(res => {
                if (res.code == 200) {
                    const list = res.data.list || [];

                    // 处理 firstThreeItems 和 remainItems
                    this.rankInfo = {
                        firstThreeItems: [],
                        remainItems: [],
                        ...res.data,
                    };

                    // 1. 拆分前三项
                    this.rankInfo.firstThreeItems = list.slice(0, 3);

                    // 如果前三项不足3个，补齐
                    while (this.rankInfo.firstThreeItems.length < 3) {
                        this.rankInfo.firstThreeItems.push({ address: '******', lp_share_cp: '******', id: 0 });
                    }

                    // 2. 剩余项，保证至少17个
                    const remainingItems = list.slice(3); // 从第四项开始
                    this.rankInfo.remainItems = remainingItems.length >= 17 ? remainingItems : [...remainingItems, ...Array(17 - remainingItems.length).fill({ address: '******', lp_share_cp: '******', id: 0 })];

                }
            })
        },
        lpusersharelist() {
            MintPool_api.lpusersharelist(this.account).then(res => {
                if (res.code == 200) {
                    const list = res.data.list || [];

                    // 处理 firstThreeItems 和 remainItems
                    this.shareInfo = {
                        firstThreeItems: [],
                        remainItems: [],
                        ...res.data,
                    };

                    // 1. 拆分前三项
                    this.shareInfo.firstThreeItems = list.slice(0, 3);

                    // 如果前三项不足3个，补齐
                    while (this.shareInfo.firstThreeItems.length < 3) {
                        this.shareInfo.firstThreeItems.push({ address: '******', addition_cp: '******', id: 0 });
                    }

                    // 2. 剩余项，保证至少17个
                    const remainingItems = list.slice(3); // 从第四项开始
                    this.shareInfo.remainItems = remainingItems.length >= 17 ? remainingItems : [...remainingItems, ...Array(17 - remainingItems.length).fill({ address: '******', addition_cp: '******', id: 0 })];

                }
            })
        },

        lpusertodayreward() {
            MintPool_api.lpusertodayreward(this.account).then(res => {
                if (res.code == 200) {
                    this.rewardInfo = res.data
                    this.rewardInfo.tolal_reward = this.rewardInfo.static_reward + this.rewardInfo.share_reward + this.cp_reward + this.list_reward
                }
            })
        },
        lpusersummary() {
            MintPool_api.lpusersummary(this.account).then(res => {
                if (res.code == 200) {
                    this.summaryInfo = res.data
                }
            })
        },
        lplist() {
            MintPool_api.lplist(this.account).then(res => {
                if (res.code == 200) {
                    this.lpConfiglist = res.data

                }
            })
        },
        lpconfig() {
            MintPool_api.lpconfig(this.account).then(res => {
                if (res.code == 200) {
                    this.MintPoolLpConfig = res.data
                }
            })
        },

        initMintPoolListInfo() {
            this.MintPoolListInfo = {
                loading: false,
                finished: false,
                list: [],
                info: {
                    page: 1,
                    page_size: 10,
                    status: this.activeMintPoolistpledge == 0 ? '1' : '0'
                }

            }
            this.lpholdlist()
        },
        lpholdlist() {
            this.MintPoolListInfo.loading = true
            MintPool_api.lpholdlist(this.MintPoolListInfo.info, this.account).then(res => {
                if (res.data != null || res.data.length > 0) {
                    if (this.MintPoolListInfo.info.page == 1) {
                        this.MintPoolListInfo.list = res.data
                    } else {
                        this.MintPoolListInfo.list = this.MintPoolListInfo.list.concat(res.data)
                    }
                    if (res.data.length < this.MintPoolListInfo.info.page_size) {

                        this.MintPoolListInfo.finished = true
                    } else {
                        this.MintPoolListInfo.info.page++
                    }

                } else {
                    this.MintPoolListInfo.finished = true

                }

                this.MintPoolListInfo.loading = false
            })
        },
        lpholdMintPoolredeemShowFunc(val) {
            if (this.MintPoolLpConfig.redeem_status == '0') return this.$toast(this.$t('lang.不可赎回'));
            this.MintPoolredeemShow = true;
            this.MintPoolredeemItems = val;

        },
        async lpholdMintPoolredeem() {
            this.MintPoolredeemShow = false;
            const sign = await web3_api.signMessage('LP Redeem', this.account)
            MintPool_api.lpholdredeem({ id: this.MintPoolredeemItems.id, signature: sign }, this.account).then(res => {
                if (res.code == 200) {
                    this.init()
                }
                this.$toast(res.msg)
            })
        },
        toprouter() {
            this.$router.go(-1);
        },
        GoMintBill() {
            this.$router.push({
                name: 'MintBill'
            })
        },
        addMintPoolShowOpen(val) {
            this.MintPoolItem = val;
            this.getERC20TokenBalance();
            this.addMintPoolShow = true;
        },
        MintPoolNumAll() {
            this.MintPoolInputNum = parseInt(this.balance);
        },
        getERC20TokenBalance() {
            this.balance = 0;
            this.balanceLoading = true;
            web3_api.getERC20TokenBalance(this.MintPoolItem.contract, this.account).then(res => {
                this.balanceLoading = false;
                this.balance = res;
            }).catch(err => {
                this.balanceLoading = false;
            })
        },
        enteraddMintPoolShow() {
            this.addMintPoolShow = false;
            this.transferERC20Token()
        },
        activeMintPoolistpledgeFunc(index) {
            this.activeMintPoolistpledge = index;
            this.initMintPoolListInfo()

        },
        onLoadMintPoolListInfo() {
            this.lpholdlist()
        }
    },
}

</script>
<style scoped lang='less'>
.MintPool {
    padding: 52px 20px;

    .recruit_title {
        color: var(rgba(255, 255, 255, 1));
        padding: 20px 0px;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.MintPoolredeemShow {
    width: 304px;
    padding: 25px 20px 16px 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #000000;
    font-size: 16px;
    font-weight: bold;

    img {
        width: 139px;
        height: 137px;
    }

    .MintPoolredeemShow_buttons {
        margin-top: 18px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;


        button {
            width: 48%;
            height: 45px;
            opacity: 1;
            border-radius: 8px;
            border: none;
        }

        .confirm {
            background: #66B4AE;
            color: #FFFFFF;
        }

        .cancel {
            background: #D1D1D1;
            color: #5E5E5E;
        }
    }
}

.addMintPoolShow {
    padding: 18px 20px 34px 20px;

    .addMintPoolShow_title {
        font-size: 16px;
        font-weight: bold;
        color: #222222;
        margin-bottom: 12px;
    }

    .addMintPoolShow_input {
        border-radius: 7px;
        width: 100%;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #D9D9D9;

        .addMintPoolShow_input_all {
            font-size: 14px;
            color: #66B4AE;
            border-left: 1px solid #E6E6E6;
            padding-left: 23px;
        }

        input {
            border: none;
        }
    }

    .addMintPoolShow_tips {
        margin-top: 8px;
        font-size: 12px;
        color: #999999;
    }

    .addMintPoolShow_btn {
        margin-top: 21px;
        height: 48px;
        width: 100%;
        background: #66B4AE;
        border-radius: 16px;
        font-size: 16px;
        color: #FFFFFF;
        font-weight: bold;
        border: none;
    }
}

.MintPoolistpledge {
    .MintPoolistpledge_box {
        .MintPoolistpledge_box_list {
            margin-top: 20px;

            .MintPoolistpledge_box_list_li {
                margin-bottom: 12px;
                background: #F7F7F7;
                border-radius: 10px;
                padding: 13px 14px;
                display: flex;
                align-items: center;

                .MintPoolistpledge_box_list_li_left {
                    margin-right: 14px;
                    width: 98px;
                    height: 123px;
                    opacity: 1;
                    border-radius: 7px;
                    background: radial-gradient(53.06% 49.59% at 50% 50.40650406504065%, #D9D9D9 0%, #E6E6E6 100%);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                    }
                }

                .MintPoolistpledge_box_list_li_right {
                    flex: 1;
                    font-size: 13px;
                    font-weight: bold;
                    color: #666666;
                    display: flex;
                    height: 123px;
                    flex-direction: column;
                    justify-content: space-between;

                    .MintPoolistpledge_box_list_li_right_button {
                        align-self: flex-end;
                        padding: 6px 21px;
                        font-size: 14px;
                        height: 32px;
                        opacity: 1;
                        border-radius: 19px;
                        background: #D4D4D4;
                    }
                }
            }
        }

        .MintPoolistpledge_box_Nolength {
            padding: 80px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #999999;
            font-size: 12px;

            img {
                width: 48px;
                height: 52px;
                margin-bottom: 10px;
            }
        }
    }

    .MintPoolistpledge_titles {
        display: flex;
        align-items: center;
        color: #999999;
        font-size: 14px;
        font-weight: bold;
        gap: 38px;

        .active {
            color: #000000;
            font-size: 16px;
            position: relative;
        }

        .active::after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -5px;
            transform: translateX(-50%);
            width: 27px;
            height: 2px;
            background: #66B4AE;
        }
    }
}

.MintPoolist {
    .MintPoolist_box {
        margin-top: 26px;
        padding: 20px 0 26px 0;
        border-top: 6px solid #F7F7F7;

        .MintPoolist_box_box {
            margin-top: 16px;
            padding: 23px 18px;
            background: #F7F7F7;
            border-radius: 16px;

            .MintPoolist_box_box_title {
                color: #999999;
                font-size: 14px;
                font-weight: 500;
            }

            .MintPoolist_box_box_num {
                margin-top: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .MintPoolist_box_box_num_left {
                    display: flex;
                    gap: 35px;
                    color: #000000;
                    font-size: 16px;
                    font-weight: bold;
                }

                .MintPoolist_box_box_num_right {
                    width: 32px;
                    height: 32px;
                    border-radius: 19px;
                    background: #66B4AE;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #FFFFFF;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }

        .MintPoolist_box_top {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #000000;
            font-weight: bold;

            .MintPoolist_box_top_logo {
                margin-right: 11px;

                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

.MintPool_box {
    padding: 7px 14px 18px 14px;
    width: 100%;
    border-radius: 16px;
    background: linear-gradient(138.26deg, rgba(102, 180, 174, 1) 0%, rgba(102, 180, 174, 1) 100%);

    .MintPool_box_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        color: #FFFFFF;
        font-weight: bold;

        img {
            width: 100px;
            height: 75px;
        }
    }

    .MintPool_box_bottom {
        margin-top: 12px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .MintPool_box_bottom_button {
            padding: 0 18px;
            border: none;
            height: 34px;
            border-radius: 6px;
            font-size: 14px;
            color: #66B4AE;
            font-weight: bold;
        }

        .MintPool_box_bottom_left {
            margin-right: 49px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &>div {
                display: flex;
                flex-direction: column;
                text-align: left;
                font-size: 10px;
                gap: 4px;
                color: rgba(255, 255, 255, 0.6);
            }
        }
    }
}

.MintPool_box2 {
    margin-top: 6px;

    .MintPool_box2_line {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: #F7F7F7;
        padding: 12px 0;
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        font-weight: bold;

        img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
        }
    }
}

.lookboxs {
    display: flex;
    width: 335px;
    margin: 0 auto;
    margin-top: 24px;
    justify-content: space-between;
    align-items: center;

    .lookbox {
        padding-top: 6px;
        position: relative;
        background-clip: padding-box;
        width: 103.77px;
        padding-bottom: 6px;
        border: 1px solid #34F7F4;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;

        .abs_look {
            position: absolute;
            right: 6px;
            top: 6px;
            padding: 3px;
            border-radius: 4px;
            background: #34F7F4;
            color: rgba(0, 0, 0, 1);
            font-size: 10px;
        }

        .box {
            width: 88.09px;
            padding: 16px 0 9px 0;
            display: flex;
            border-radius: 8px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: linear-gradient(0deg, #34F7F4 0%, #FFFFFF 100%);
        }

        .box_num {
            font-size: 18px;
            color: rgba(0, 0, 0, 1);
        }

        .box_title {
            font-size: 12px;
            color: rgba(0, 0, 0, 1);
        }

        img {
            margin-top: 10px;
            margin-bottom: 14px;
            width: 26px;
            height: 26px;
        }
    }
}
</style>